import React from 'react';
import classNames from 'classnames';

const Video: React.FC<any> = ({ data, classes, autoplay = true, controls = true }) => {
    if (!data?.url || Object.keys(data.url).length === 0) return <></>;

    return <video className={classNames(classes?.video)} src={data.url} autoPlay={autoplay} controls={controls} />;
};

export default Video;
/**
 *  <video
        ref={this.videoRef}
        className={`${className} video`}
        width={width}
        height={height}
        src={url && url.url ? url.url : url}
        style={style}
        controls={controls}
        onClick={!controls ? this.playVideo : null}
        autoPlay={autoplay}
    />
 */
