import React, { useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import { AxiosError } from 'axios';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import { IframeSettings } from 'components/creatives-v2/creative-types/template-creative.class';
import TemplateHelpers from 'components/data/TemplateHelpers';
import EditorData from 'components/editor-data/EditorData';
import TemplateDesignerService from 'components/template-designer/services/template-designer.service';
import Button from 'components/ui-components-v2/Button';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import { TDTemplateAsset } from 'components/template-management/types/template-management.type';
import { CreativeV2TemplateEnriched } from '../../creative-editor/types/creativeV2.type';
import { AdobeRenderStatus } from '../types/adobe-status';

import '../styles/indesign.scss';

interface Props {
    format: string;
    creative: CreativeV2TemplateEnriched;
    data: IframeSettings<null>;
    scale?: number;
}

interface PreviewData {
    status: number;
    url?: string;
    thumbnail?: string;
    renderProgress?: number;
    loading: boolean;
    statusMessage?: string;
}

const Indesign = ({ format, data, creative, scale }: Props) => {
    const [previewData, setPreviewData] = useState<PreviewData | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    /**
     * Renders the video preview
     */
    const renderPreview = async () => {
        const language = EditorData.getLanguage();

        const template = TemplateManager.getTemplateByIdentifier(creative.data.templateId) as TDTemplateAsset;
        const templateInput = creative.data.templateInput;

        if (!template) return;

        const adobePayload = TemplateHelpers.parseTdInDesign(
            {},
            {
                blockData: templateInput,
                format: format,
                template: {
                    type: template.type,
                    identifier: template.data.templateSetup?.templateSetup.adobe?.identifier || template.data.identifier,
                    downloadUrl: template.data.templateSetup?.templateSetup.adobe?.downloadUrl,
                    layers: template.data.templateSetup?.layers ?? {},
                    interfaceSetup: template.data.interfaceSetup,
                    pages: template.data.templateSetup?.pages ?? {},
                    formats: template.data.templateSetup?.formats ?? []
                },
                preview: true
            },
            language,
            true
        );

        const token = await TemplateDesignerService.getInDesignToken();
        if (!token) {
            SnackbarUtils.error('Something went wrong while trying to render the PDF preview. Please try again later.');
            return;
        }

        const response = await TemplateDesignerService.startPreviewRender(token, adobePayload);
        if (!response) {
            SnackbarUtils.error('Something went wrong while trying to render the PDF preview. Please try again later.');
            return;
        }

        if (response.status === AdobeRenderStatus.RENDER_STATUS_COMPLETE) {
            setPreviewData({
                loading: false,
                url: response.url,
                thumbnail: response.previewUrl,
                renderProgress: 100,
                status: response.status
            });
            return;
        }

        setPreviewData({
            loading: true,
            url: response.url,
            thumbnail: response.previewUrl,
            renderProgress: 0,
            status: response.status
        });

        if (intervalRef.current) clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => checkIfRenderisComplete(response.uuid, token), 2000);
    };

    /**
     * Checks if the render is complete. If it is, it will stop the interval and set the video url
     * if there render is not complete, it will update the render progress
     * if there is an error, it will stop the interval and show an error message
     *
     * @param uuid the uuid of the render job
     * @param token a auth token for the api call
     */
    const checkIfRenderisComplete = async (uuid: string, token: string) => {
        try {
            const response = await TemplateDesignerService.checkPreviewRender(uuid, token);

            if (!response) return;
            if (response.status === AdobeRenderStatus.RENDER_STATUS_COMPLETE) {
                if (intervalRef.current) clearInterval(intervalRef.current);
                setPreviewData({
                    ...previewData,
                    url: response.url,
                    thumbnail: response.previewUrl,
                    status: response.status,
                    loading: false
                });
                return;
            }
            setPreviewData({
                ...previewData,
                url: response.url,
                thumbnail: response.previewUrl,
                loading: true,
                status: response.status
            });

            if (response.status === AdobeRenderStatus.RENDER_STATUS_ERROR) {
                throw response;
            }
        } catch (error) {
            sessionStorage.removeItem(TemplateDesignerService.INDESIGN_TOKEN);
            if (intervalRef.current) clearInterval(intervalRef.current);

            if (error instanceof AxiosError) {
                setPreviewData({
                    ...previewData,
                    loading: false,
                    status: error.response?.data.status || AdobeRenderStatus.RENDER_STATUS_ERROR,
                    renderProgress: 100
                });
                SnackbarUtils.error(error.response?.data.statusMessage);
                return;
            }

            setPreviewData({ ...previewData, loading: false, status: AdobeRenderStatus.RENDER_STATUS_ERROR, renderProgress: 100 });
            SnackbarUtils.error('Something went wrong with rendering the PDF');
        }
    };

    const scaledWidth = data.width * (scale || 1);
    const scaledHeight = data.height * (scale || 1);

    const renderStatus = previewData?.status || 0;

    return (
        <div className="indesign" style={{ width: scaledWidth, height: scaledHeight }}>
            {previewData && !previewData.loading && previewData.thumbnail && <img className="indesign__preview" src={previewData.thumbnail} />}

            {(!previewData || (!previewData.loading && !previewData.url)) && (
                <div className="indesign__empty-preview">
                    <div className="indesign__empty-preview__button">
                        <Button variant="contained" color="primary" onClick={renderPreview}>
                            Render PDF preview
                        </Button>
                    </div>
                </div>
            )}

            {previewData &&
                previewData.url &&
                (previewData.status === AdobeRenderStatus.RENDER_STATUS_COMPLETE || renderStatus === AdobeRenderStatus.RENDER_STATUS_COMPLETE) && (
                    <div className="indesign__rerender-container">
                        <Tooltip title="Render new PDF preview">
                            <div className="indesign__rerender-container__rerender-button" onClick={renderPreview}>
                                <UpdateIcon />
                            </div>
                        </Tooltip>
                    </div>
                )}

            {previewData && previewData.loading && (
                <div className="indesign__loading-container">
                    <div className="indesign__loading-container__text">
                        {(() => {
                            if (renderStatus === AdobeRenderStatus.RENDER_STATUS_QUEUE) {
                                return 'Waiting in queue';
                            }

                            if (renderStatus === AdobeRenderStatus.RENDER_STATUS_RENDERING) {
                                return 'Rendering new PDF.\nThis may take a few minutes.';
                            }

                            return 'loading...';
                        })()}
                    </div>
                    {renderStatus >= 0 && (
                        <div className="indesign__loading-container__spinner">
                            <CircularProgress />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export { Indesign };
